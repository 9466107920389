
import * as React from "react";
import { observer, inject } from 'mobx-react';
import { RoomStore, RouterStore } from '../../stores';
import MediaDevices from '../../components/MediaDevices';
import PubAndSub from '../../components/PubAndSub';
import TracksPlayer from "../../components/TracksPlayer";
import BufferSourceAudioControl from '../../components/BufferSourceAudioControl';
import AudioMixingControl from '../../components/AudioMixingControl';
import AudioVolumeControl from '../../components/AudioVolumeControl';
import AudioBufferControl from '../../components/AudioBufferControl';
import DirectLiveStreaming from "../../components/DirectLiveStreaming";
import TranscodingLiveStreaming from "../../components/TranscodingLiveStreaming";
import LogControl from "../../components/LogControl";
import VideoOptimizationModeControl from "../../components/VideoOptimizationModeControl";
import TrackShotsControl from "../../components/TrackShotsControl";
import TextMessages from '../../components/TextMessages';
import MirrorControl from '../../components/MirrorControl';
import VolumeIndicatorControl from '../../components/VolumeIndicatorControl';
import ActiveCheck from '../../components/ActiveCheck';
import MediaRelay from "../../components/MediaRelay";

interface State {
  localTrackMirror: boolean;
  remoteTrackMirror: boolean;
}

interface Props {
  room: RoomStore;
  router: RouterStore;
}

@inject("room", "router")
@observer
export class RoomPage extends React.Component<Props, State> {
  public state: State = {
    localTrackMirror: true,
    remoteTrackMirror: false
  }

  public setMirror = (direction: "local" | "remote", mirror: boolean) => {
    if (direction === "local") {
      this.setState({ localTrackMirror: mirror });
    } else {
      this.setState({ remoteTrackMirror: mirror });
    }
  }

  public async componentDidMount(): Promise<void> {
    if (!this.props.room.userID && !this.props.room.roomToken) {
      this.props.router.push("/");
    }
  }

  public componentWillUnmount(): void {
    this.props.room.leave();
  }

  public render(): JSX.Element {
    return (
      <div className="room-page">
        <p>Current ConnectionState: {this.props.room.connectionState}</p>
        <hr />
        <LogControl />
        <VolumeIndicatorControl room={this.props.room} />
        <ActiveCheck room={this.props.room} />
        <MirrorControl 
          localTrackMirror={this.state.localTrackMirror}
          remoteTrackMirror={this.state.remoteTrackMirror}
          setMirror={this.setMirror}
        />
        <PubAndSub room={this.props.room} />
        <BufferSourceAudioControl room={this.props.room} />
        <AudioMixingControl room={this.props.room} />
        <AudioVolumeControl room={this.props.room} />
        <AudioBufferControl room={this.props.room} />
        <DirectLiveStreaming room={this.props.room} />
        <TranscodingLiveStreaming room={this.props.room} />
        <TextMessages room={this.props.room} />
        <TrackShotsControl room={this.props.room} />
        <VideoOptimizationModeControl room={this.props.room} />
		<MediaRelay room={this.props.room}/>
        <TracksPlayer room={this.props.room} localTrackMirror={this.state.localTrackMirror} remoteTrackMirror={this.state.remoteTrackMirror} />
      </div>
    );
  }
}
