import * as React from "react";
import { observer } from 'mobx-react';

import { NONE } from "../../constants";
import { RoomStore } from "../../stores";

interface State {
  audioTrackID: string;
  audioBufferAttrs: string;
}

interface Props {
  room: RoomStore;
}

@observer
export default class AudioBufferControl extends React.Component<Props, State> {
  state: State = {
    audioTrackID: NONE,
    audioBufferAttrs: "",
  };

  handleAudioFrame = (buffer: AudioBuffer) => {
    this.setState({
      audioBufferAttrs: JSON.stringify({
        now: Date.now(),
        duration: buffer.duration,
        length: buffer.length,
        numberOfChannels: buffer.numberOfChannels,
        sampleRate: buffer.sampleRate,
      })
    });

  };

  handleChangeAudioTrack = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const lastTrack = this.props.room.audioTracks.find(t => t.trackID === this.state.audioTrackID);
    if (lastTrack) {
      lastTrack.off("audioFrame", this.handleAudioFrame);
    }
    this.setState({ audioTrackID: e.target.value });
    const curTrack = this.props.room.audioTracks.find(t => t.trackID === e.target.value);
    if (curTrack) {
      curTrack.on("audioFrame", this.handleAudioFrame);
    }
  };

  public render(): JSX.Element {
    return <div className="audio-buffer-control-container">
      <h2>AudioBuffer</h2>
      <label htmlFor="audio-buffer-control-audioTrackID">audioTrack: </label>
      <select id="audio-buffer-control-audioTrackID" value={this.state.audioTrackID} onChange={e => this.handleChangeAudioTrack(e)}>
        <option value={NONE}>{NONE}</option>
        {this.props.room.audioTracks.map(t => (
          <option key={t.trackID} value={t.trackID}>{t.trackID}</option>
        ))}
      </select>
      <div>audioBuffer state: {this.state.audioBufferAttrs}</div>
    </div>;
  }
}

