import * as React from "react";
import { observer, inject } from 'mobx-react';
import { RoomStore, RouterStore, PromptStore } from '../../stores';
import QNRTC, { QNClientMode, QNClientRole, QNTransportPolicy } from "qnweb-rtc";
import { API } from '../../constants/http';
import { request } from '../../utils';
import { NONE } from "../../constants"

type RoleOption = typeof NONE | QNClientRole;
type ModeOption = typeof NONE | QNClientMode;

interface State {
  appid: string;
  roomName: string;
  userName: string;
  roomToken: string;
  userData: string;
  mode: ModeOption, 
  role: RoleOption,
  policy: QNTransportPolicy;
  checkResult: string;
}

interface Props {
  room: RoomStore;
  router: RouterStore;
  prompt: PromptStore;
}

@inject("room", "prompt", "router")
@observer
export class HomePage extends React.Component<Props, State> {
  public state: State = {
    appid: "fx1yx9lz2",
    roomName: "",
    userName: "",
    roomToken: "",
    userData: "",
    role: NONE,
    mode: NONE,
    policy: QNTransportPolicy.PREFER_UDP,
    checkResult: ""
  };

  private onJoinRoom = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.props.room.setRoomInfo({
      appID: this.state.appid, userID: this.state.userName, roomName: this.state.roomName, roomToken: this.state.roomToken
    });
    this.props.prompt.showLoading({ content: "加入房间中" });
    try {
      if (!this.state.roomToken) {
        const api = this.props.room.userID === "admin" ? API.CREATE_ROOM_TOKEN : API.JOIN_ROOM_TOKEN;
        // 此处服务器 URL 仅用于 Demo 测试！随时可能 修改/失效，请勿用于 App 线上环境！
        // 此处服务器 URL 仅用于 Demo 测试！随时可能 修改/失效，请勿用于 App 线上环境！
        // 此处服务器 URL 仅用于 Demo 测试！随时可能 修改/失效，请勿用于 App 线上环境！
        const requestURL = `${api(this.props.room.roomName, this.props.room.userID, this.props.room.appID)}?bundleId=demo-rtc.qnsdk.com`;
        const token: string = await request(requestURL, "text");
        this.props.room.setRoomInfo({ roomToken: token });
      } else {
        this.props.room.setRoomInfo({ roomToken: this.state.roomToken });
      }

      if (this.state.mode !== NONE) {
        await this.props.room.rtcClient.setClientMode(this.state.mode);
      }
      if (this.state.role !== NONE) {
        await this.props.room.rtcClient.setClientRole(this.state.role);
      }
      await this.props.room.rtcClient.join(this.props.room.roomToken, this.state.userData);
      this.props.router.push(`/room`);
      this.props.prompt.closeLoading();
    } catch (e) {
      // TODO show error prompt
      console.error("joinroom error", e);
      (window as any).__test__joinRoomError = e;
      this.props.prompt.closeLoading();
    }
  };

  public render(): JSX.Element {
    return (
      <div>
        <h1>QiNiu RTC Web Demo V4</h1>
        <form id="joinroom" onSubmit={this.onJoinRoom}>
          <div className="joinroom-config-item">
            <label>Appid: </label>
            <input id="input_appid" required value={this.state.appid} type="text" onChange={e => this.setState({ appid: e.target.value })} />
          </div>
          <div className="joinroom-config-item">
            <label>UserName:</label>
            <input id="input_userid" required={!this.state.roomToken} value={this.state.userName} type="text" onChange={e => this.setState({ userName: e.target.value })} />
          </div>
          <div className="joinroom-config-item">
            <label>RoomName:</label>
            <input id="input_roomname" required={!this.state.roomToken} value={this.state.roomName} type="text" onChange={e => this.setState({ roomName: e.target.value })} />
          </div>
          <div className="joinroom-config-item">
            <label>RoomToken:</label>
            <input value={this.state.roomToken} type="text" onChange={e => this.setState({ roomToken: e.target.value })} />
          </div>
          <div className="joinroom-config-item">
            <label>UserData:</label>
            <input value={this.state.userData} type="text" onChange={e => this.setState({ userData: e.target.value })} />
          </div>
          <div className="joinroom-config-item">
            <label>Mode:</label>
            <select value={this.state.mode} onChange={e => {
              const mode = e.target.value as ModeOption;
              this.setState({mode})
            }}>
              <option value={NONE}>{NONE}</option>;
              <option value={QNClientMode.RTC}>{QNClientMode.RTC}</option>;
              <option value={QNClientMode.LIVE}>{QNClientMode.LIVE}</option>;
            </select>
          </div>
          <div className="joinroom-config-item">
            <label>Role:</label>
            <select value={this.state.role} onChange={e => {
              const role = e.target.value as RoleOption;
              this.setState({role})
            }}>
              <option value={NONE}>{NONE}</option>;
              <option value={QNClientRole.BROADCASTER}>{QNClientRole.BROADCASTER}</option>;
              <option value={QNClientRole.AUDIENCE}>{QNClientRole.AUDIENCE}</option>;
            </select>
          </div>
          <div className="joinroom-config-item">
            <label>TransportPolicy:</label>
            <select value={this.state.policy} onChange={e => {
              const policy = e.target.value as QNTransportPolicy;
              this.setState({ policy });
              QNRTC.setTransportPolicy(policy);
            }}>
              {Object.values(QNTransportPolicy).map(p => {
                return <option key={p} value={p}>{p}</option>;
              })}
            </select>
          </div>
          <button id="btn_joinroom" type="submit" form="joinroom">JoinRoom</button>
        </form>
        <div>
          <button onClick={() => {
            this.setState({ checkResult: "checking" });
            QNRTC.checkSystemRequirements()
              .then(result => this.setState({ checkResult: JSON.stringify(result, null, 2) }))
              .catch(e => this.setState({ checkResult: e.message }));
          }}>checkSystemRequirements</button>
          <pre>{this.state.checkResult}</pre>
        </div>
        <div className="meta_container">
          <p>Build Time: {process.env.REACT_APP_BUILD_TIME}</p>
          <p>App Version: {process.env.REACT_APP_VERSION}</p>
          <p>SDK Version: {QNRTC.VERSION}-{process.env.REACT_APP_LATEST_COMMIT_HASH}</p>
        </div>
      </div>
    );
  }
}
