import * as React from "react";
import { observer } from 'mobx-react';
import QNRTC, { QNLogLevel } from "qnweb-rtc";

interface State {
  level: QNLogLevel;
}

interface Props { }

@observer
export default class LogControl extends React.Component<Props, State> {
  state: State = {
    level: QNLogLevel.VERBOSE
  };

  public render(): JSX.Element {
    return <div className="log-control-container">
      <h2>Log Control</h2>
      <label htmlFor="log-level-sel">log level: </label>
      <select id="log-level-sel" value={this.state.level} onChange={e => {
        const level = e.target.value as QNLogLevel;
        this.setState({ level });
        QNRTC.setLogLevel(level);
      }}>
        {Object.values(QNLogLevel).map(level => {
          return <option key={level} value={level}>{level}</option>;
        })}
      </select>
    </div>;
  }
}

