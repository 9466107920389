'use strict';

var __chunk_1 = require('./chunk-b806d793.js');

require('detect-browser');

var semver = require('semver');

require('mobile-detect');

function mediaDevicesShim() {
  var constraintsToChrome_ = function constraintsToChrome_(c) {
    if (typeof c !== "object" || c.mandatory || c.optional) {
      return c;
    }

    var cc = {};
    Object.keys(c).forEach(function (key) {
      if (key === "require" || key === "advanced" || key === "mediaSource") {
        return;
      }

      var r = typeof c[key] === "object" ? c[key] : {
        ideal: c[key]
      };

      if (r.exact !== undefined && typeof r.exact === "number") {
        r.min = r.max = r.exact;
      }

      var oldname_ = function oldname_(prefix, name) {
        if (prefix) {
          return prefix + name.charAt(0).toUpperCase() + name.slice(1);
        }

        return name === "deviceId" ? "sourceId" : name;
      };

      if (r.ideal !== undefined) {
        cc.optional = cc.optional || [];
        var oc = {};

        if (typeof r.ideal === "number") {
          oc[oldname_("min", key)] = r.ideal;
          cc.optional.push(oc);
          oc = {};
          oc[oldname_("max", key)] = r.ideal;
          cc.optional.push(oc);
        } else {
          oc[oldname_("", key)] = r.ideal;
          cc.optional.push(oc);
        }
      }

      if (r.exact !== undefined && typeof r.exact !== "number") {
        cc.mandatory = cc.mandatory || {};
        cc.mandatory[oldname_("", key)] = r.exact;
      } else {
        ["min", "max"].forEach(function (mix) {
          if (r[mix] !== undefined) {
            cc.mandatory = cc.mandatory || {};
            cc.mandatory[oldname_(mix, key)] = r[mix];
          }
        });
      }
    });

    if (c.advanced) {
      cc.optional = (cc.optional || []).concat(c.advanced);
    }

    return cc;
  };

  var shimConstraints_ = function shimConstraints_(constraints, func) {
    if (semver.gt(__chunk_1.browser.version, "61.0.0")) {
      return func(constraints);
    }

    constraints = JSON.parse(JSON.stringify(constraints));

    if (constraints && typeof constraints.audio === "object") {
      var remap = function remap(obj, a, b) {
        if (a in obj && !(b in obj)) {
          obj[b] = obj[a];
          delete obj[a];
        }
      };

      constraints = JSON.parse(JSON.stringify(constraints));
      remap(constraints.audio, "autoGainControl", "googAutoGainControl");
      remap(constraints.audio, "noiseSuppression", "googNoiseSuppression");
      constraints.audio = constraintsToChrome_(constraints.audio);
    }

    if (constraints && typeof constraints.video === "object") {
      // Shim facingMode for mobile & surface pro.
      var face = constraints.video.facingMode;
      face = face && (typeof face === "object" ? face : {
        ideal: face
      });
      var getSupportedFacingModeLies = semver.lt(__chunk_1.browser.version, "66.0.0");

      if (face && (face.exact === "user" || face.exact === "environment" || face.ideal === "user" || face.ideal === "environment") && !(navigator.mediaDevices.getSupportedConstraints && navigator.mediaDevices.getSupportedConstraints().facingMode && !getSupportedFacingModeLies)) {
        delete constraints.video.facingMode;
        var matches = undefined;

        if (face.exact === "environment" || face.ideal === "environment") {
          matches = ["back", "rear"];
        } else if (face.exact === "user" || face.ideal === "user") {
          matches = ["front"];
        }

        if (matches) {
          // Look for matches in label, or use last cam for back (typical).
          return navigator.mediaDevices.enumerateDevices().then(function (devices) {
            devices = devices.filter(function (d) {
              return d.kind === "videoinput";
            });
            var dev = devices.find(function (d) {
              return matches.some(function (match) {
                return d.label.toLowerCase().includes(match);
              });
            });

            if (!dev && devices.length && matches.includes("back")) {
              dev = devices[devices.length - 1]; // more likely the back cam
            }

            if (dev) {
              constraints.video.deviceId = face.exact ? {
                exact: dev.deviceId
              } : {
                ideal: dev.deviceId
              };
            }

            constraints.video = constraintsToChrome_(constraints.video);
            return func(constraints);
          });
        }
      }

      constraints.video = constraintsToChrome_(constraints.video);
    }

    return func(constraints);
  }; // Returns the result of getUserMedia as a Promise.


  var getUserMediaPromise_ = function getUserMediaPromise_(constraints) {
    return new Promise(function (resolve, reject) {
      navigator.getUserMedia(constraints, resolve, reject);
    });
  };

  if (!navigator.mediaDevices) {
    navigator.mediaDevices = {
      getUserMedia: getUserMediaPromise_,
      enumerateDevices: function enumerateDevices() {
        return new Promise(function (resolve) {
          var kinds = {
            audio: "audioinput",
            video: "videoinput"
          };
          return window.MediaStreamTrack.getSources(function (devices) {
            resolve(devices.map(function (device) {
              return {
                label: device.label,
                kind: kinds[device.kind],
                deviceId: device.id,
                groupId: ""
              };
            }));
          });
        });
      }
    };
  }

  if (!navigator.mediaDevices.getSupportedConstraints) {
    navigator.mediaDevices.getSupportedConstraints = function () {
      return {
        deviceId: true,
        echoCancellation: true,
        facingMode: true,
        frameRate: true,
        height: true,
        width: true
      };
    };
  } // A shim for getUserMedia method on the mediaDevices object.
  // TODO(KaptenJansson) remove once implemented in Chrome stable.


  if (!navigator.mediaDevices.getUserMedia) {
    navigator.mediaDevices.getUserMedia = function (constraints) {
      return getUserMediaPromise_(constraints);
    };
  } else {
    // Even though Chrome 45 has navigator.mediaDevices and a getUserMedia
    // function which returns a Promise, it does not accept spec-style
    // constraints.
    var origGetUserMedia = navigator.mediaDevices.getUserMedia.bind(navigator.mediaDevices);

    navigator.mediaDevices.getUserMedia = function (cs) {
      return shimConstraints_(cs, function (c) {
        return origGetUserMedia(c).then(function (stream) {
          if (c.audio && !stream.getAudioTracks().length || c.video && !stream.getVideoTracks().length) {
            stream.getTracks().forEach(function (track) {
              track.stop();
            });
            throw new DOMException("", "NotFoundError");
          }

          return stream;
        }, function (e) {
          return Promise.reject(e);
        });
      });
    };
  }
} // adapter.js 8eca2831b0d11cc14ffb6193d0d49044c6dd9252


function mediaDevicesShim$1() {
  var getUserMedia_ = function getUserMedia_(constraints, onSuccess, onError) {
    constraints = JSON.parse(JSON.stringify(constraints));
    return navigator.mozGetUserMedia(constraints, onSuccess, function (e) {
      onError(e);
    });
  }; // Returns the result of getUserMedia as a Promise.


  var getUserMediaPromise_ = function getUserMediaPromise_(constraints) {
    return new Promise(function (resolve, reject) {
      getUserMedia_(constraints, resolve, reject);
    });
  };

  if (!navigator.mediaDevices) {
    navigator.mediaDevices = {
      getUserMedia: getUserMediaPromise_,
      addEventListener: function addEventListener() {},
      removeEventListener: function removeEventListener() {}
    };
  }

  navigator.mediaDevices.enumerateDevices = navigator.mediaDevices.enumerateDevices || function () {
    return new Promise(function (resolve) {
      var infos = [{
        kind: "audioinput",
        deviceId: "default",
        label: "",
        groupId: ""
      }, {
        kind: "videoinput",
        deviceId: "default",
        label: "",
        groupId: ""
      }];
      resolve(infos);
    });
  };
}

if (__chunk_1.browser.name === "chrome") {
  mediaDevicesShim();
}

if (__chunk_1.browser.name === "firefox") {
  mediaDevicesShim$1();
}