import * as React from "react";
import { observer } from 'mobx-react';
import { RoomStore } from "../../stores/roomStore";
import QNRTC, {
  QNCameraVideoTrack,
  QNCanvasVideoTrack,
  QNChromeExtensionSourceType,
  QNCustomAudioTrack,
  QNCustomVideoTrack,
  QNLocalTrack,
  QNMicrophoneAudioTrack,
  QNScreenVideoTrack,
  QNVideoOptimizationMode
} from "qnweb-rtc";
import CanvasTrack from "../CanvasTrack";
import LowStream from "../LowStream";
import MediaDevices from "../MediaDevices";
import ElectronScreenCapture from "../ElectronScreenCapture";

type PublishKey = "camera_mic_360p" | "camera_mic_480p" | "camera_mic_720p" | "camera_mic_1080p" |
  "screen_share" | "screen_share_with_audio" | "screen_share_in_electron" | "screen_share_using_extension" | "mic_only" | "camera_only" | "buffer_audio" |
  "url_audio" | "video_file";

type FacingMode = "environment" | "user";


interface Props {
  room: RoomStore,
}

interface State {
  facingMode: FacingMode;

  // 上传的 audio 文件
  bufferAudioFile?: File;

  // audio url 地址
  audioURL: string;

  // video url 地址
  videoURL: string;

  // 播放的视频标签地址
  videoSrc: string[];

  // 需要取消发布的 trackID 数组
  selectedUnpublishTrackIDs: string[];

  // 需要订阅的 trackID 数组
  selectedSubscribeTrackIDs: string[];

  // 需要取消订阅的 trackID 数组
  selectedUnsubscribeTrackIDs: string[];

  // 需要重复发布的 track
  republishTrack: QNLocalTrack | undefined;

  customVideoTrack: QNCustomVideoTrack | undefined;
  customAudioTrack: QNCustomAudioTrack | undefined;
  switchCustomTrack: boolean;
}

@observer
export default class PubAndSub extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handlePublishCanvasTrack = this.handlePublishCanvasTrack.bind(this);
  }

  state: State = {
    facingMode: "environment",
    audioURL: "https://docs.qnsdk.com/eva_edm.mp3",
    videoURL: "https://docs.qnsdk.com/starnight_snow.mp4",
    selectedUnpublishTrackIDs: [],
    selectedSubscribeTrackIDs: [],
    selectedUnsubscribeTrackIDs: [],
    republishTrack: undefined,
    videoSrc: [],
    customVideoTrack: undefined,
    customAudioTrack: undefined,
    switchCustomTrack: false,
  };

  private async handlePublish(key: PublishKey, e?: any): Promise<void> {
    let tracks: QNLocalTrack[] = [];
    switch (key) {
      case "buffer_audio":
        if (!this.state.bufferAudioFile) {
          console.error("no file!");
          return;
        }
        const bufferTrack = await QNRTC.createBufferSourceAudioTrack({ source: this.state.bufferAudioFile, tag: "bufferSourceAudio" });
        tracks.push(bufferTrack);
        break;
      case "url_audio":
        const urlTrack = await QNRTC.createBufferSourceAudioTrack({ source: this.state.audioURL, tag: "bufferSourceAudio" });
        tracks.push(urlTrack);
        break;
      case "camera_mic_360p":
        tracks = [...await QNRTC.createMicrophoneAndCameraTracks(
          { encoderConfig: "LOW", tag: "microphone" }, { encoderConfig: "360p", facingMode: this.state.facingMode })];
        break;
      case "camera_mic_480p":
        tracks = [...await QNRTC.createMicrophoneAndCameraTracks(
          { encoderConfig: "STANDARD", tag: "microphone" }, { encoderConfig: "480p", facingMode: this.state.facingMode })];
        break;
      case "camera_mic_720p":
        tracks = [...await QNRTC.createMicrophoneAndCameraTracks(
          { encoderConfig: "STANDARD_STEREO", tag: "microphone" }, { encoderConfig: "720p", facingMode: this.state.facingMode })];
        break;
      case "camera_mic_1080p":
        tracks = [...await QNRTC.createMicrophoneAndCameraTracks(
          { encoderConfig: "HIGH_STEREO", tag: "microphone" }, { encoderConfig: "1080p", facingMode: this.state.facingMode })];
        break;
      case "screen_share":
        try {
          const screenTracks = await QNRTC.createScreenVideoTrack({
            encoderConfig: { width: 1920, height: 1080, frameRate: 15, bitrate: 3000 },
          });
          if (!Array.isArray(screenTracks)) {
            tracks = [screenTracks];
          }
        } catch (error) {
          console.error(error);
        }
        break;
      case "screen_share_using_extension":
        console.log("chrome extention available: ", await QNRTC.isChromeExtensionAvailable());
        try {
          const screenTracks = await QNRTC.createScreenVideoTrack({
            encoderConfig: { width: 1920, height: 1080, frameRate: 15, bitrate: 3000 }, chromeExtensionSourceType: QNChromeExtensionSourceType.ALL
          });
          if (!Array.isArray(screenTracks)) {
            tracks = [screenTracks];
          }
        } catch (error) {
          console.error(error);
        }
        break;
      case "screen_share_with_audio":
        try {
          const screenTracks = await QNRTC.createScreenVideoTrack(
            { encoderConfig: "1080p", screenAudioTag: "screen-audio", screenVideoTag: "screen-video" },
            "enable"
          );
          if (Array.isArray(screenTracks)) {
            tracks.push(...screenTracks);
          }
        } catch (error) {
          console.error(error);
        }
        break;
      case "screen_share_in_electron":
        try {
          const screenTracks = await QNRTC.createScreenVideoTrack({
            electronScreenSourceID: e ? e.electronScreenSourceID : undefined
          });
          if (Array.isArray(screenTracks)) {
            tracks = screenTracks;
          } else {
            tracks = [screenTracks];
          }
        } catch (error) {
          console.error(error);
        }
        break;
      case "camera_only":
        tracks.push(await QNRTC.createCameraVideoTrack({ encoderConfig: { width: { ideal: 1920 }, height: { ideal: 1080 }, frameRate: 30, bitrate: 1000 }, facingMode: this.state.facingMode }));
        break;
      case "mic_only":
        tracks.push(await QNRTC.createMicrophoneAudioTrack({ encoderConfig: "HIGH", tag: "microphone" }));
        break;
      case "video_file":
        let mediaStream: MediaStream;
        if ((e.target as any).captureStream) {
          mediaStream = (e.target as any).captureStream();
        } else if ((e.target as any).mozCaptureStream) {
          mediaStream = (e.target as any).mozCaptureStream();
        } else {
          return;
        }
        const mediaTracks = mediaStream.getTracks();
        let customAudioTrack: QNCustomAudioTrack | undefined = undefined;
        let customVideoTrack: QNCustomVideoTrack | undefined = undefined;
        for (const mediaTrack of mediaTracks) {
          switch (mediaTrack.kind) {
            case "audio":
              if (this.state.switchCustomTrack) {
                if (!this.state.customAudioTrack) break;
                const oldMediaTrack = this.state.customAudioTrack.getMediaStreamTrack();
                if (oldMediaTrack) {
                  oldMediaTrack.stop();
                }
                (this.state.customAudioTrack as any).setMediaStreamTrack(mediaTrack)
                  .then(() => {
                    console.log("setMediaStreamTrack success");
                    this.props.room.syncPublishedTracks([...this.props.room.publishedTracks.filter(t => t.trackID !== this.state.customAudioTrack!.trackID)]);
                    this.props.room.syncPublishedTracks([...this.props.room.publishedTracks, this.state.customAudioTrack!]);
                  })
                  .catch((e: any) => console.log("setMediaStreamTrack error", e))
                  .finally(() => console.log(this.state.customAudioTrack, mediaTrack));
              } else {
                customAudioTrack = await QNRTC.createCustomAudioTrack({ mediaStreamTrack: mediaTrack, bitrate: 64 });
                this.setState({ customAudioTrack });
                tracks.push(customAudioTrack);
              }
              break;
            case "video":
              if (this.state.switchCustomTrack) {
                if (!this.state.customVideoTrack) break;
                const oldMediaTrack = this.state.customVideoTrack.getMediaStreamTrack();
                if (oldMediaTrack) {
                  oldMediaTrack.stop();
                }
                (this.state.customVideoTrack as any).setMediaStreamTrack(mediaTrack)
                  .then(() => {
                    console.log("setMediaStreamTrack success");
                    this.props.room.syncPublishedTracks([...this.props.room.publishedTracks.filter(t => t.trackID !== this.state.customVideoTrack!.trackID)]);
                    this.props.room.syncPublishedTracks([...this.props.room.publishedTracks, this.state.customVideoTrack!]);
                  })
                  .catch((e: any) => console.log("setMediaStreamTrack error", e))
                  .finally(() => console.log(this.state.customVideoTrack, mediaTrack));
              } else {
                customVideoTrack = await QNRTC.createCustomVideoTrack({ mediaStreamTrack: mediaTrack, bitrate: 2000, optimizationMode: QNVideoOptimizationMode.MOTION });
                this.setState({ customVideoTrack });
                tracks.push(customVideoTrack);
              }
              break;
          }
        }
    }
    tracks.forEach(v => v.once("ended", () => {
      window.alert(`track ended, trackID: ${v.trackID}, tag: ${v.tag}`);
      this.props.room.syncPublishedTracks([...this.props.room.publishedTracks.filter(t => t.trackID !== v.trackID)]);
    }));
    if (tracks.length === 0) return;
    await this.props.room.rtcClient.publish(tracks);
    for (let i of tracks) {
      console.log(`trackID：${i.trackID}，mediaStreamTrack：${JSON.stringify(i.getMediaStreamTrack())}`);
    }
    this.props.room.syncPublishedTracks([...this.props.room.publishedTracks, ...tracks]);
  }

  private handlePublishCanvasTrack = async (track: QNCanvasVideoTrack) => {
    await this.props.room.rtcClient.publish([track]);
    this.props.room.syncPublishedTracks([...this.props.room.publishedTracks, track]);
  };

  private handlePubTrackWithLowStream = async (track: QNCameraVideoTrack | QNScreenVideoTrack) => {
    try {
      await this.props.room.rtcClient.publish([track]);
      this.props.room.syncPublishedTracks([...this.props.room.publishedTracks, track]);
    } catch (e) {
      console.error("publish error", e);
      track.destroy();
    }
  };

  private handlePubTrackWithDeviceId = async (track: QNCameraVideoTrack | QNMicrophoneAudioTrack) => {
    await this.props.room.rtcClient.publish(track);
    this.props.room.syncPublishedTracks([...this.props.room.publishedTracks, track]);
  };

  private handleUnpublish = async () => {
    const tracks = this.props.room.publishedTracks.filter(track => this.state.selectedUnpublishTrackIDs.includes(track.trackID as string));
    await this.props.room.rtcClient.unpublish(tracks);
    this.props.room.syncPublishedTracks(this.props.room.publishedTracks.filter(track => !tracks.includes(track)));
    tracks.forEach(t => t.destroy());
    this.setState({ selectedUnpublishTrackIDs: [] });
  };

  private handleSubscribe = async () => {
    const tracks = this.props.room.notYetSubscribedTracks.filter(track => this.state.selectedSubscribeTrackIDs.includes(track.trackID as string));
    await this.props.room.rtcClient.subscribe(tracks);
    for (let i of tracks) {
      console.log(`trackID：${i.trackID}，mediaStreamTrack：${JSON.stringify(i.getMediaStreamTrack())}`);
    }
    this.props.room.syncNotYetSubscribedTracks(this.props.room.notYetSubscribedTracks.filter(track => !tracks.includes(track)));
    this.props.room.syncSubscribedTracks([...this.props.room.subscribedTracks, ...tracks]);
    this.setState({ selectedSubscribeTrackIDs: [] });
  };

  private handleUnsubscribe = async () => {
    const tracks = this.props.room.subscribedTracks.filter(track => this.state.selectedUnsubscribeTrackIDs.includes(track.trackID as string));
    await this.props.room.rtcClient.unsubscribe(tracks);
    this.props.room.syncSubscribedTracks(this.props.room.subscribedTracks.filter(track => !tracks.includes(track)));
    this.props.room.syncNotYetSubscribedTracks([...this.props.room.notYetSubscribedTracks, ...tracks]);
    this.setState({ selectedUnsubscribeTrackIDs: [] });
  };

  private handleChangeInputFile = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = e.target.files ? e.target.files[0] : undefined;
    if (!file) return;
    this.state.bufferAudioFile = file;
  };

  private handleUpdateUnpublishTracks = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const trackIDs = [];
    for (let i = 0; i < e.target.selectedOptions.length; i += 1) {
      const option = e.target.selectedOptions.item(i);
      if (!option) continue;
      trackIDs.push(option.value);
    }
    this.setState({ selectedUnpublishTrackIDs: trackIDs });
  };

  private handleUpdateSubscribeTracks = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const trackIDs = [];
    for (let i = 0; i < e.target.selectedOptions.length; i += 1) {
      const option = e.target.selectedOptions.item(i);
      if (!option) continue;
      trackIDs.push(option.value);
    }
    this.setState({ selectedSubscribeTrackIDs: trackIDs });
  };

  private handleUpdateUnsubscribeTracks = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const trackIDs = [];
    for (let i = 0; i < e.target.selectedOptions.length; i += 1) {
      const option = e.target.selectedOptions.item(i);
      if (!option) continue;
      trackIDs.push(option.value);
    }
    this.setState({ selectedUnsubscribeTrackIDs: trackIDs });
  };

  private handleRepublish = async () => {
    let targetTrack: QNLocalTrack;
    if (!this.state.republishTrack) {
      const track = await QNRTC.createCameraVideoTrack();
      targetTrack = track;
      this.setState({ republishTrack: track });
    } else {
      targetTrack = this.state.republishTrack;
      await this.props.room.rtcClient.unpublish(targetTrack);
      this.props.room.syncPublishedTracks(this.props.room.publishedTracks.filter(track => track !== targetTrack));
    }

    await this.props.room.rtcClient.publish(targetTrack);
    this.props.room.syncPublishedTracks([...this.props.room.publishedTracks, targetTrack]);
  };

  public render(): JSX.Element {
    return <>
      <div className="get-tracks-config-container">
        <h2>采集相关配置</h2>
        <label htmlFor="config-facingMode">facingMode: </label>
        <select id="config-facingMode" value={this.state.facingMode} onChange={(e) => this.setState({ facingMode: e.target.value as FacingMode })}>
          <option value="environment">environment</option>
          <option value="user">user</option>
        </select>
      </div>
      <h2>发布订阅相关</h2>
      <button id="btn_publish_360p" onClick={() => this.handlePublish("camera_mic_360p")}>发布麦克风+摄像头采集(360p)</button>
      <br />
      <button id="btn_publish_480p" onClick={() => this.handlePublish("camera_mic_480p")}>发布麦克风+摄像头采集(480p)</button>
      <br />
      <button id="btn_publish_720p" onClick={() => this.handlePublish("camera_mic_720p")}>发布麦克风+摄像头采集(720p)</button>
      <br />
      <button id="btn_publish_1080p" onClick={() => this.handlePublish("camera_mic_1080p")}>发布麦克风+摄像头采集(1080p)</button>
      <br />
      <button id="btn_publish_screen" onClick={() => this.handlePublish("screen_share")}>发布屏幕共享</button>
      <button id="btn_publish_screen_with_audio" onClick={() => this.handlePublish("screen_share_with_audio")}>发布屏幕共享+加系统声音</button>
      <button id="btn_publish_screen_using_extension" onClick={() => this.handlePublish("screen_share_using_extension")}>发布插件屏幕共享</button>
      <br />
      <button id="btn_publish_mic_only" onClick={() => this.handlePublish("mic_only")}>仅发布麦克风</button>
      <button id="btn_publish_camera_only" onClick={() => this.handlePublish("camera_only")}>仅发布摄像头</button>
      <button id="btn_republish" onClick={() => this.handleRepublish()}>republish</button>
      <br />
      <label>选择音乐文件：</label>
      <input
        type="file"
        accept=".mp3, .ogg"
        className="local-audio-file"
        onChange={this.handleChangeInputFile}
      />
      <button id="btn_publishmusic" onClick={() => this.handlePublish("buffer_audio")}>发布本地音乐</button>
      <br />
      <label>输入音乐地址：</label>
      <input
        type="text"
        className="online-audio"
        value={this.state.audioURL}
        onChange={e => this.setState({ audioURL: e.target.value })}
      />
      <button id="btn_publishurlmusic" onClick={() => this.handlePublish("url_audio")}>发布在线音乐</button>
      <br />
      <label>输入视频地址：</label>
      <input
        type="text"
        className="online-video"
        value={this.state.videoURL}
        onChange={e => this.setState({ videoURL: e.target.value })}
      />
      <button onClick={() => {
        this.setState({ switchCustomTrack: false });
        this.setState({ videoSrc: [...this.state.videoSrc, this.state.videoURL] });
      }}>发布在线视频</button>
      <button onClick={() => {
        this.setState({ switchCustomTrack: true });
        this.setState({ videoSrc: [...this.state.videoSrc, this.state.videoURL] });
      }}>切换在线视频</button>
      {this.state.videoSrc.map((item, index) => (
        <video 
          key={index} 
          crossOrigin="anonymous" 
          onLoadedMetadata={e => this.handlePublish("video_file", e)}
          controls 
          autoPlay 
          style={{ width: "45%" }} 
          src={item} 
        />)
      )}
      <MediaDevices
        cameras={this.props.room.cameras}
        microphones={this.props.room.microphones}
        playbackDevices={this.props.room.playbackDevices}
        handlePub={this.handlePubTrackWithDeviceId}
        audioTracks={this.props.room.subscribedTracks.filter(t => t.isAudio())}
      />
      <CanvasTrack handlePub={this.handlePublishCanvasTrack} />
      <LowStream handlePub={this.handlePubTrackWithLowStream} />
      <br />
      <ElectronScreenCapture pubFunc={(e?: { electronScreenSourceID?: string; }) => this.handlePublish("screen_share_in_electron", e)} />
      <label>选择 track unpublish: </label>
      <br />
      <select id="sel_localtrack" multiple onChange={this.handleUpdateUnpublishTracks} value={this.state.selectedUnpublishTrackIDs}>
        {this.props.room.publishedTracks.map(t => (
          <option key={t.trackID} value={t.trackID}>{t.trackID}({t.tag})</option>
        ))}
      </select>
      <br />
      <button id="btn_unpub" onClick={this.handleUnpublish}>取消发布</button>
      <br />
      <label>选择 track subscribe: </label>
      <br />
      <select id="sel_sub" multiple onChange={this.handleUpdateSubscribeTracks} value={this.state.selectedSubscribeTrackIDs}>
        {this.props.room.notYetSubscribedTracks.map(t => (
          <option key={t.trackID} value={t.trackID}>{t.trackID}({t.tag})</option>
        ))}
      </select>
      <br />
      <button id="btn_sub" onClick={this.handleSubscribe}>订阅</button>
      <br />
      <label>选择 track unsubscribe: </label>
      <br />
      <select id="sel_unsub" multiple onChange={this.handleUpdateUnsubscribeTracks} value={this.state.selectedUnsubscribeTrackIDs}>
        {this.props.room.subscribedTracks.map(t => (
          <option key={t.trackID} value={t.trackID}>{t.trackID}({t.tag})</option>
        ))}
      </select>
      <br />
      <button id="btn_unsub" onClick={this.handleUnsubscribe}>取消订阅</button>
    </>;
  }
}