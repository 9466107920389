'use strict';

function _interopDefault(ex) {
  return ex && typeof ex === 'object' && 'default' in ex ? ex['default'] : ex;
}

var detectBrowser$1 = require('detect-browser');

var semver = require('semver');

var MobileDetect = _interopDefault(require('mobile-detect'));

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var defineProperty = _defineProperty;

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      defineProperty(target, key, source[key]);
    });
  }

  return target;
}

var objectSpread = _objectSpread;

function detectBrowser() {
  var originBrowser = detectBrowser$1.detect();

  if (!navigator || !navigator.appVersion || !originBrowser) {
    return originBrowser;
  }

  var IChrome = /(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9\.]+)(:?\s|$)/;
  var chromeInfo = IChrome.exec(window.navigator.appVersion);

  if (!chromeInfo || !chromeInfo[1]) {
    return originBrowser;
  }

  return objectSpread({}, originBrowser, {
    chromeVersion: chromeInfo[1]
  });
}

var isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
var isChrome = !!window.chrome;
var isIOS = navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i);
var browser = detectBrowser() || {};
var isMobile = !!new MobileDetect(navigator.userAgent).mobile();
var isElectron = navigator.userAgent.toLowerCase().indexOf("electron") > -1;

function baseSupportCheck() {
  try {
    if (!window) {
      return false;
    }

    if (!("RTCPeerConnection" in window)) {
      return false;
    }

    if (!("WebSocket" in window)) {
      return false;
    }

    if (!navigator) {
      return false;
    }

    if (!navigator.mediaDevices) {
      return false;
    }

    if (!navigator.mediaDevices.getUserMedia) {
      return false;
    }

    return true;
  } catch (e) {
    return false;
  }
}

function supportGetSettings() {
  return "getSettings" in MediaStreamTrack.prototype;
}

function supportGetDisplayMedia() {
  var hasGetDisplayMediaAPI = navigator && navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
  var supportDisplaysurface = navigator && navigator.mediaDevices && navigator.mediaDevices.getSupportedConstraints && navigator.mediaDevices.getSupportedConstraints().displaySurface; // 在 Firefox 支持标准的选择采集源之前，继续使用老的 API，因为反正都不需要插件

  if (isFirefox) {
    return !!hasGetDisplayMediaAPI && !!supportDisplaysurface;
  }

  return !!hasGetDisplayMediaAPI;
}

function browserCheck() {
  if (!browser) {
    return {
      support: baseSupportCheck(),
      supportRestartICE: true,
      getDisplayMedia: supportGetDisplayMedia(),
      disconnectAudioNode: true
    };
  }

  switch (browser.name) {
    case "chrome":
      return {
        support: baseSupportCheck(),
        mediaStreamDest: semver.gte(browser.version, "55.0.0"),
        replaceTrack: semver.gte(browser.version, "65.0.0"),
        screenSharing: semver.gte(browser.version, "55.0.0"),
        connectionState: semver.gte(browser.version, "72.0.0"),
        stats: semver.gte(browser.version, "67.0.0"),
        ondevicechange: semver.gte(browser.version, "57.0.0"),
        minMaxWithIdeal: semver.gte(browser.version, "56.0.0"),
        supportTransceivers: semver.gte(browser.version, "69.0.0"),
        // https://www.chromestatus.com/feature/5723303167655936
        unifiedPlan: semver.gte(browser.version, "72.0.0"),
        supportRestartICE: true,
        getReceivers: semver.gte(browser.version, "59.0.0"),
        needH264FmtpLine: semver.lte(browser.version, "51.0.0"),
        audioContextOptions: true,
        getDisplayMedia: supportGetDisplayMedia(),
        disconnectAudioNode: true,
        setPlaybackDevice: true
      };

    case "ios":
    case "safari":
      return {
        support: baseSupportCheck(),
        replaceTrack: semver.gte(browser.version, "11.0.0"),
        stats: false,
        ondevicechange: false,
        connectionState: true,
        mediaStreamDest: semver.gte(browser.version, "12.0.0"),
        // https://developer.mozilla.org/en-US/docs/Web/API/Screen_Capture_API
        screenSharing: semver.gte(browser.version, "13.0.0"),
        unifiedPlan: semver.gte(browser.version, "12.1.0"),
        supportTransceivers: true,
        minMaxWithIdeal: false,
        supportRestartICE: true,
        getReceivers: true,
        audioContextOptions: true,
        getDisplayMedia: supportGetDisplayMedia(),
        disconnectAudioNode: false
      };

    case "firefox":
      return {
        support: baseSupportCheck(),
        replaceTrack: true,
        stats: true,
        ondevicechange: semver.gte(browser.version, "52.0.0"),
        connectionState: true,
        mediaStreamDest: true,
        screenSharing: true,
        minMaxWithIdeal: true,
        unifiedPlan: true,
        // https://developer.mozilla.org/en-US/docs/Mozilla/Firefox/Releases/59
        supportTransceivers: semver.gte(browser.version, "59.0.0"),
        supportRestartICE: false,
        getReceivers: true,
        // Firefox 55 以后不会抛出 audioContext 构造函数错误，但是依旧还是 ignore
        audioContextOptions: semver.gte(browser.version, "55.0.0"),
        getDisplayMedia: supportGetDisplayMedia(),
        disconnectAudioNode: true
      };

    case "crios":
    case "ios-webview":
      return {
        support: baseSupportCheck(),
        getDisplayMedia: supportGetDisplayMedia(),
        unifiedPlan: true,
        supportTransceivers: true,
        supportRestartICE: true,
        disconnectAudioNode: true
      };

    default:
      return {
        support: baseSupportCheck(),
        supportRestartICE: true,
        getDisplayMedia: supportGetDisplayMedia(),
        disconnectAudioNode: true
      };
  }
}

var browserReport = browserCheck();
exports.browser = browser;
exports._objectSpread = objectSpread;
exports.browserReport = browserReport;
exports.isFirefox = isFirefox;
exports.isIOS = isIOS;
exports.isChrome = isChrome;
exports.isMobile = isMobile;
exports.supportGetSettings = supportGetSettings;
exports.isElectron = isElectron;