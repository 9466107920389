import * as React from "react";
import { observer } from 'mobx-react';
import { RoomStore } from "../../stores/roomStore";
import { QNAudioMixer, QNAudioSourceState, QNMicrophoneAudioTrack } from "qnweb-rtc";
import MusicBar from "../MusicBar";
import { NONE } from "../../constants";

interface Props {
  room: RoomStore;
}

interface State {
  /**
   * 背景音乐文件
   */
  musicFile?: File;
  /**
   * 背景音乐URL
   */
  musicURL: string;
  /**
   * 选择的麦克风 trackID
   */
  selectedMicrophoneTrackID: string;
  /**
   * mixer 对象
   */
  audioMixerMap: { [key: string]: QNAudioMixer[]; };
  /**
   * 外部音频处理状态
   */
  sourceState: QNAudioSourceState | "";
  /**
   * 本地播放音量
   */
  playingVolume: string;
}

@observer
export default class AudioMixingControl extends React.Component<Props, State> {
  state: State = {
    musicURL: "https://docs.qnsdk.com/eva_edm.mp3",
    selectedMicrophoneTrackID: NONE,
    audioMixerMap: {},
    sourceState: "",
    playingVolume: "1",
  };

  private handleChangeMusicFile = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = e.target.files ? e.target.files[0] : undefined;
    if (!file) return;
    this.state.musicFile = file;
  };

  private handleCreateAudioMixer() {
    const microphoneTrack = this.props.room.publishedTracks.filter(t => t.trackID === this.state.selectedMicrophoneTrackID)[0];
    if (!microphoneTrack) {
      console.error("no selected microphone track, selectedMicrophoneTrackID: ", this.state.selectedMicrophoneTrackID);
      return;
    }
    let audioMixer: QNAudioMixer;
    if (this.state.musicFile) {
      audioMixer = (microphoneTrack as QNMicrophoneAudioTrack).createAudioMixer(this.state.musicFile);
    } else {
      audioMixer = (microphoneTrack as QNMicrophoneAudioTrack).createAudioMixer(this.state.musicURL);
    }
    audioMixer.on("state-changed", this.updateSourceState);

    let newMixerMap = { ...this.state.audioMixerMap };
    if (!newMixerMap[this.state.selectedMicrophoneTrackID]) {
      newMixerMap[this.state.selectedMicrophoneTrackID] = [audioMixer];
    } else {
      newMixerMap[this.state.selectedMicrophoneTrackID] = [...newMixerMap[this.state.selectedMicrophoneTrackID], audioMixer];
    }
    this.setState({ audioMixerMap: newMixerMap });
  }

  updateSourceState = (state: QNAudioSourceState) => {
    this.setState({ sourceState: state });
  };

  componentWillUnmount() {
    Object.values(this.state.audioMixerMap).forEach(mixers => {
      for (const m of mixers) {
        m.off("state-changed", this.updateSourceState);
      }
    });
  }

  handleSetPlayingVolume(microphoneTrackID: string, index: number) {
    const mixer: QNAudioMixer = this.state.audioMixerMap[microphoneTrackID][index];
    if (!mixer) return;
    mixer.setPlayingVolume(Number(this.state.playingVolume));
  }

  public render(): JSX.Element {
    return <div className="audio-mixing-control-container ">
      <h2>混音控制</h2>
      <div className="create-audio-mixer-container">
        <label htmlFor="audio-mixer-microphone-track">选择麦克风 Track: </label>
        <select
          id="audio-mixer-microphone-track"
          value={this.state.selectedMicrophoneTrackID}
          onChange={e => this.setState({ selectedMicrophoneTrackID: e.target.value })}>
          <option value={NONE}>{NONE}</option>
          {
            this.props.room.microphoneTracks.map(t => <option key={t.trackID} value={t.trackID}>{t.trackID}</option>)
          }
        </select>
        <br />
        <label htmlFor="audio-mixer-source-input">选择音乐文件： </label>
        <input
          id="audio-mixer-source-input"
          type="file"
          accept=".mp3, .ogg"
          onChange={this.handleChangeMusicFile}
        />
        <br />
        <label htmlFor="audio-mixer-source-url">输入音乐地址：</label>
        <input
          className="online-audio"
          type="text"
          value={this.state.musicURL}
          onChange={e => this.setState({ musicURL: e.target.value })}
        />
        <br />
        <button id="api-createAudioMixer" onClick={() => this.handleCreateAudioMixer()}>createAudioMixer</button>
      </div>
      <div>
        {
          Object.entries(this.state.audioMixerMap).map(([microphoneTrackID, mixerList]) => {
            return mixerList.map((mixer, index) => {
              return <div className="audio-mixer-item" key={index}>
                <h4>AudioMixer: {index + 1}(microphoneTrack: {microphoneTrackID})</h4>
                <button className="audio-mixer-control-start" onClick={() => mixer.start()}>start</button>
                <button className="audio-mixer-control-stop" onClick={() => mixer.stop()}>stop</button>
                <button className="audio-mixer-control-pause" onClick={() => mixer.pause()}>pause</button>
                <button className="audio-mixer-control-resume" onClick={() => mixer.resume()}>resume</button>
                <label htmlFor="audio-mixer-playing-volume-input">local playing volume: </label>
                <input type="text" id="audio-mixer-playing-volume-input" value={this.state.playingVolume} onChange={e => this.setState({ playingVolume: e.target.value })} />
                <button onClick={() => this.handleSetPlayingVolume(microphoneTrackID, index)}>setVolume</button>
                <br />
                <span>{this.state.sourceState}</span>
                <MusicBar controller={mixer} />
              </div>;
            }, "");
          })
        }
      </div>
      <br />
    </div>;
  }
}